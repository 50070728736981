function Main() {

    return (
        <div className={'black-bg w-screen h-screen'}>
            <div className={'flex justify-center h-full items-center flex-col grow'}>
                <div className="headline text-center text-white text-4xl md:text-6xl mb-4">
                    Coming Soon
                </div>
                <div className="text-center  text-xl text-white"><a href="mailto:contact@polygenx.com">contact@polygenx.com</a></div>
            </div>
        </div>
    );
}

export default Main;
